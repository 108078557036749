<template>
  <v-app>
    <vue-snotify></vue-snotify>
    <loading :active.sync="$store.state.loading"></loading>
    <v-main>
      <v-banner
          single-line
          sticky
          app
          class="black white--text text-center text-uppercase font-weight-bold text-lg"
          v-if="$vuetify.breakpoint.lgAndUp"
      >
        {{ $t('DISFRUTA HASTA FINAL DE MES DE TU PRIMERA CITA GRATIS') }}
      </v-banner>
      <v-banner
          single-line
          sticky
          app
          class="black white--text text-center text-uppercase font-weight-bold text-sm"
          v-else
      >
        {{ $t('DISFRUTA HASTA FINAL DE MES DE TU PRIMERA CITA GRATIS') }}
      </v-banner>
      <v-toolbar v-if="show_toolbar">
        <v-btn class="v-btn-small pl-0 pr-0" text @click="go_back">
          <v-icon>mdi-arrow-left</v-icon>
          Volver
        </v-btn>
        <v-spacer />
        <v-btn class="v-btn-small pl-0 pr-0" v-if="show_btn_logout" text @click="go_to_reservas">
          <v-icon>mdi-calendar-heart</v-icon>
          Mis reservas
        </v-btn>
        <v-spacer />
        <v-btn class="v-btn-small pl-0 pr-0" v-if="show_btn_logout" text @click="logout">
          <v-icon>mdi-logout</v-icon>
          Cerrar sesión
        </v-btn>
      </v-toolbar>
      <div v-if="show_logo" class="mt-5">
        <img style="max-width:120px" class="mx-auto d-block" src="./assets/logo-elha.png" alt="logo-elha">
        <p class="text-center mb-2" style="font-size: 25px" v-if="$vuetify.breakpoint.lgAndUp">
          {{ this.$t('La mejor tecnología del mercado y los') }} <strong>{{$t('mejores precios por sesiones sueltas')}}</strong>,  {{$t('sin tener que comprar bonos')}}.
        </p>
        <p class="text-center mb-2 px-3" style="font-size: 19px" v-else>
          {{ this.$t('La mejor tecnología del mercado y los') }} <strong>{{$t('mejores precios por sesiones sueltas')}}</strong>,  {{$t('sin tener que comprar bonos')}}.
        </p>
      </div>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import EventBus from "@/plugins/bus";

export default {
  name: 'App',

  methods: {
    go_back(){
      const next_page = this.$route.query.next
      if(next_page){
        this.$router.push({name: next_page})
      }else{
        this.$router.go(-1)
      }
    },

    go_to_reservas(){
      this.$router.push({name: "reservas"})
    },

    logout(){
      this.$store.dispatch('auth/logout')
      this.$router.replace('/')
    }
  },

  created () {
    EventBus.$on('show_message', (payload) => {
      switch (payload.type) {
        case 'error':
          this.$snotify.error(
              payload.text,
              payload.title
          )
          break
        case 'success':
          this.$snotify.success(
              payload.text,
              payload.title
          )
          break
      }
    })
  },

  computed: {
    show_toolbar() {
      return this.$store.state.common.show_toolbar
    },
    show_btn_logout() {
      return this.$store.state.auth.user
    },
    show_logo(){
      return this.$store.state.show_logo
    }
  },

  components: {
    Loading
  }
}
</script>

<style lang="less">
// Fuentes
@font-face {
  font-family: 'Halenoir';
  src: url('~@/assets/fonts/Halenoir-ExtraLight.eot');
  src: url('~@/assets/fonts/Halenoir-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('~@/assets/fonts/Halenoir-ExtraLight.woff2') format('woff2'),
  url('~@/assets/fonts/Halenoir-ExtraLight.woff') format('woff'),
  url('~@/assets/fonts/Halenoir-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
.v-application {
  font-family: "Halenoir", "Roboto", sans-serif !important;
}

// PNotify
.ui-pnotify-title {
  text-transform: none !important;
}

// PNotify y Bootstrap
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

.pointer{ cursor: pointer; }

.label-text-large {
  label{
    font-size: 1.3em !important;
  }
}
.v-btn-small {
  font-size: 0.67rem !important;
}

@media (max-width: 960px) {
  .text-sm {
    font-size: 0.560rem;
  }
}

@media (min-width: 961px) {
}
</style>
