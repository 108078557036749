import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'centros',
        component: () => import('./views/Centros.vue')
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('./views/Calendar')
    },
    {
        path: '/confirmar-reserva',
        name: 'confirmar_reserva',
        component: () => import('./views/ConfirmarReserva')
    },
    {
      path: '/centro/:slug',
      name: 'centro',
      component: () => import('./views/Centro.vue')
    }

]

const router = new VueRouter({
    routes
})

export default router
