import Vue from 'vue'
import Snotify, {SnotifyPosition} from 'vue-snotify';
import 'vue-snotify/styles/material.css'
Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 2500,
    showProgressBar: false,
    titleMaxLength: 20,
  }
})
