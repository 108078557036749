import Vue from 'vue'

const state = {
    id: null,
    centro: null,
    fecha: null,
    hora: null,
    origen: 'w',
    servicio: null,
    precioReserva: 0,
    descuento: 0,
    empleado: null,
    precioReservaSinDescuento: 0,
    sexo: null,
    codigoPedido: generateCodigoPedido(),
    descripcion: "",
    include_genitales_masculinos: false
}

const mutations = {
    reset_reserva(state){
        state.id = null
        state.centro = null
        state.fecha = null
        state.hora = null
        state.origen = 'w'
        state.servicio = null
        state.precioReserva = 0
        state.descuento = 0
        state.empleado = null
        state.precioReservaSinDescuento = 0
        state.codigoPedido = generateCodigoPedido()
        state.include_genitales_masculinos = false
    },

    set_empleado(state, empleado){
        state.empleado = empleado
    },

    set_fecha(state, fecha){
        state.fecha = fecha
    },

    set_hora(state, hora){
        state.hora = hora
    },

    set_centro(state, centro){
        state.centro = centro
    },

    set_sexo (state, sexo) {
      state.sexo = sexo
    },

    set_servicio(state, servicio){
        state.servicio = servicio.id
        state.descripcion = servicio.descripcion
        if(state.sexo === 'f'){
            state.precioReserva = servicio.precio
            state.precioReservaSinDescuento = servicio.precio
        }else{
            state.precioReserva = servicio.precio_chico
            state.precioReservaSinDescuento = servicio.precio_chico
        }
    },
    set_include_genitales_masculinos(state, include_genitales_masculinos){
        state.include_genitales_masculinos = include_genitales_masculinos
    },
}

function generateCodigoPedido() {
    return 'xxxx-xxxx-yxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    }).toUpperCase();
}

const actions = {

    async save_reserva({commit}, reserva){
        const { data } = await Vue.axios({
            method: 'POST',
            url: `/public/reserva/gratis`,
            data: reserva
        })

        return data
    },

    async cancel_reserva({commit}, reserva_id){
        const { data } = await Vue.axios({
            method: 'DELETE',
            url: `/private/reserva/cancelar`,
            data: {
                idReserva: reserva_id
            }
        })

        return data
    },

    async get_horas({commit}, {fecha, centro, servicio, include_genitales_masculinos}){
        const { data } = await Vue.axios({
            method: 'GET',
            url: `/public/servicios/${servicio.id}/calendario`,
            params: {
                fecha,
                centro,
                include_genitales_masculinos
            }
        })

        return data
    }

}

export default {
    namespaced: true,
    actions,
    state,
    mutations
}
