import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.css'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/vue-i18n'

require('@/plugins')

Vue.config.productionTip = false

new Vue({
  created () {
    this.loadGoogleTagManager()
    this.loadFacebookPixel()
  },
  methods: {
    loadGoogleTagManager () {
      // const host = window.location.hostname
      let gtmId = 'GTM-MN7STP3'

      // if (host === 'sesiongratuita.elhadepilacionlaser.com') {
      //   gtmId = 'GTM-MN7STP3'
      // } else if (host === 'sedutagratuita.centrielha.it') {
      //   gtmId = 'GTM-TN1SGJ2'
      // }

      if (gtmId) {
        // Inserta el código JavaScript
        (function (w, d, s, l, i) {
          w[l] = w[l] || []
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          })
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== 'dataLayer' ? '&l=' + l : ''
          j.async = true
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
          f.parentNode.insertBefore(j, f)
        })(window, document, 'script', 'dataLayer', gtmId)

        // Inserta el código de <noscript>
        const noscriptIframe = document.createElement('iframe')
        noscriptIframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`
        noscriptIframe.height = '0'
        noscriptIframe.width = '0'
        noscriptIframe.style.display = 'none'
        noscriptIframe.style.visibility = 'hidden'

        const noscriptElement = document.getElementById('gtm-noscript')
        noscriptElement.appendChild(noscriptIframe)
      }
    },
    loadFacebookPixel () {
      // const host = window.location.hostname
      let pixelId = '914957830368930'

      // if (host === 'sesiongratuita.elhadepilacionlaser.com') {
      //   pixelId = '914957830368930'
      // } else if (host === 'sedutagratuita.centrielha.it') {
      //   pixelId = 'otro_id_de_tu_facebook_pixel'
      // }

      if (pixelId) {
        // Inserta el código JavaScript del píxel de Facebook
        !function (f, b, e, v, n, t, s) {
          if (f.fbq) return
          n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(
              arguments)
          }
          if (!f._fbq) f._fbq = n
          n.push = n
          n.loaded = !0
          n.version = '2.0'
          n.queue = []
          t = b.createElement(e)
          t.async = !0
          t.src = v
          s = b.getElementsByTagName(e)[0]
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js')

        fbq('init', pixelId)
        fbq('track', 'PageView')

        // Inserta el código de <noscript> del píxel de Facebook
        const noscriptImg = document.createElement('img')
        noscriptImg.height = 1
        noscriptImg.width = 1
        noscriptImg.style.display = 'none'
        noscriptImg.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`

        const noscriptElement = document.getElementById('fb-pixel-noscript')
        noscriptElement.appendChild(noscriptImg)
      }
    }
  },
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
