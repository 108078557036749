import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
import auth from '@/store/modules/auth'
import centro from '@/store/modules/centro'
import reserva from '@/store/modules/reserva'
import common from '@/store/modules/common'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: ['auth', 'reserva']
})

export default new Vuex.Store({
    state: {
        loading: false,
        show_logo: false,
        reserva_confirmada: false,
        sms_code: null
    },
    mutations: {
        set_loading(state, bool) {
            state.loading = bool
        },
        set_show_logo(state, bool) {
            state.show_logo = bool
        },
        set_reserva_confirmada(state, bool) {
            state.reserva_confirmada = bool
        },
        set_sms_code(state, code) {
            state.sms_code = code
        }
    },
    actions: {},
    modules: {
        centro,
        reserva,
        auth,
        common
    },
    plugins: [vuexLocal.plugin]
})
